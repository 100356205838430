import {
  AUTH_USER,
  ADD_ADDRESS,
  FORCE_ADDRESS,
  IS_LOADING,
  IS_LOADED,
  SHOW_ERROR,
  SHOW_USERID,
  CLEAR_ERROR,
  SCAN_CODE,
  ADD_BABYINFO,
  EDIT_BABYINFO,
  FORCE_BABYINFO,
  READ_MESSAGE,
  GET_REWARD,
  GET_PREGNANT,
  ADD_TO_CART,
  SEND_ORDER,
  REDEEM_VOUCHER,
  DONATE_POINTS,
  DONATE_WISHER,
  ADD_FEEDBACK,
  GET_QUESTION,
  GET_SAMPLE,
  GET_NEWS,
  GET_ARTICLE,
  GET_CONTEST,
  GET_JOURNEY,
  GET_BLOG,
  GET_QUEST,
  GET_WISHINGTREE,
  GET_VOUCHER,
  GET_DONATE,
  GET_BRAND_INFO,
  LOG_OUT,
  FETCH_SAMPLE,
  EDIT_CART,
  EDIT_PROFILE,
  SELECT_LANG,
  ALREADY_LOGIN,
  JUST_LOGIN,
  FORCE_EDIT,
  SEND_PREGNANT,
  SSO_POPUP,
  ADD_CAMPAIGN_ID
} from "../types";
import axios from "axios";
import tokenConfig from "./helperFunction";

export const authenticateUser = (data, callback) => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/signin", data)
    .then(res => {
      if (res.data.flow && res.data.flow === "resetPassword") {
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
        callback();
      } else {
        dispatch({ type: JUST_LOGIN });
        dispatch({ type: AUTH_USER, payload: res.data });
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
      }
    })
    .catch(err => {
      console.log(err.response.data);
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: SHOW_USERID, payload: err.response.data.userId });
      dispatch({ type: IS_LOADED });
    });
};

export const registerUser = (data, callback) => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/signup", data)
    .then(res => {
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback(res.data.uuid, res.data.referCode);
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const verifyUser = (data, callback) => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/verify", data)
    .then(res => {
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback(res.data.uuid, res.data.referCode, res.data.orderId);
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const checkSession = () => (dispatch, getState) => {
  const token = getState().userReducer.token;
  if (token) {
    dispatch({ type: IS_LOADING });
    axios
      .post("/drypers-baby-club/api/user/session", { token }, tokenConfig(getState))
      .then(res => {
        dispatch({ type: AUTH_USER, payload: res.data });
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
      })
      .catch(err => {
        dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
        dispatch({ type: LOG_OUT });
        dispatch({ type: IS_LOADED });
      });
  }
};

export const addAddress = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/address/add", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: ADD_ADDRESS, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const forceAddAddress = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/address/add", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: FORCE_ADDRESS, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      // callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const scanCode = (data, callback) => (dispatch, getState) => {
  const isLoading = getState().pageReducer.isLoading;
  if (!isLoading) {
    dispatch({ type: IS_LOADING });
    axios
      .post("/drypers-baby-club/api/user/scancode", data, tokenConfig(getState))
      .then(res => {
        dispatch({ type: SCAN_CODE, payload: res.data });
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
        callback(res.data.points, res.data.code_group, res.data.brand, res.data.format, res.data.size);
      })
      .catch(err => {
        dispatch({
          type: SHOW_ERROR,
          payload: err.response && err.response.data.error
        });
        dispatch({ type: IS_LOADED });
        callback();
      });
  }
};

export const addBabyInfo = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/babyinfo/add", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: ADD_BABYINFO, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const editBabyInfo = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/babyinfo/add", data, tokenConfig(getState))
    .then(res => {
      console.log(res.data);
      dispatch({ type: EDIT_BABYINFO, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const forceAddBabyInfo = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/babyinfo/add", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: FORCE_BABYINFO, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      // callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const readMessage = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/message/read", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: READ_MESSAGE, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const getRewards = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/rewards/catalog/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_REWARD, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getPregnant = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/rewards/pregnant/fetch", tokenConfig(getState))
    .then(res => {
      // console.log(res.data);
      dispatch({ type: GET_PREGNANT, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getNews = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/news/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_NEWS, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getArticle = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/article/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_ARTICLE, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getContest = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/contest/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_CONTEST, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getJourney = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/journey/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_JOURNEY, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getBlog = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/blog/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_BLOG, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getQuest = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/quest/fetch", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_QUEST, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getWishingTree = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/wishes/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_WISHINGTREE, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getVoucher = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/rewards/voucher/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_VOUCHER, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const getDonates = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/rewards/donate/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_DONATE, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err });
      dispatch({ type: IS_LOADED });
    });
};

export const sendOrder = (data, callback) => (dispatch, getState) => {
  const isLoading = getState().pageReducer.isLoading;
  if (!isLoading) {
    dispatch({ type: IS_LOADING });
    axios
      .post("/drypers-baby-club/api/rewards/redeem", data, tokenConfig(getState))
      .then(res => {
        dispatch({ type: SEND_ORDER, payload: res.data });
        callback(res.data.order.id, res.data.order.trackingId);
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
        dispatch({ type: "CLEAR_CART" });
      })
      .catch(err => {
        dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
        dispatch({ type: IS_LOADED });
      });
  }
};

export const sendOrderPregnant = (data, callback) => (dispatch, getState) => {
  const isLoading = getState().pageReducer.isLoading;
  if (!isLoading) {
    dispatch({ type: IS_LOADING });
    axios
      .post("/drypers-baby-club/api/rewards/redeemPregnant", data, tokenConfig(getState))
      .then(res => {
        dispatch({ type: SEND_PREGNANT, payload: res.data });
        callback(res.data.order.id, res.data.order.trackingId);
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
        dispatch({ type: "CLEAR_CART" });
      })
      .catch(err => {
        dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
        dispatch({ type: IS_LOADED });
      });
  }
};

export const getSample = () => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/sample/fetch")
    .then(res => {
      dispatch({ type: FETCH_SAMPLE, payload: res.data });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const sendSample = (data, callback) => (dispatch, getState) => {
  const isLoading = getState().pageReducer.isLoading;
  if (!isLoading) {
    dispatch({ type: IS_LOADING });
    axios
      .post("/drypers-baby-club/api/sample/redeem", data, tokenConfig(getState))
      .then(res => {
        dispatch({ type: GET_SAMPLE, payload: res.data });
        dispatch({ type: CLEAR_ERROR });
        dispatch({ type: IS_LOADED });
        callback(res.data.trackingId);
      })
      .catch(err => {
        dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
        dispatch({ type: IS_LOADED });
      });
  }
};

export const redeemVouc = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/rewards/voucher/redeem", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: REDEEM_VOUCHER, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback(res.data.voucher.id);
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const donatePoints = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/rewards/donate/redeem", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: DONATE_POINTS, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const donateWisher = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  return axios
    .post("/drypers-baby-club/api/rewards/donate/wisher", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: DONATE_WISHER, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      return res; // return the response
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
      throw err; // throw the error to be caught in the try-catch block
    });
};
export const voucherUse = (data, callback) => (dispatch, getState) => {
  axios
    .post("/drypers-baby-club/api/rewards/voucher/use", data, tokenConfig(getState))
    .then(() => callback())
    .catch(() => callback());
};

export const addFeedback = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/sample/question/answer", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: ADD_FEEDBACK });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.error) {
        dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      } else {
        dispatch({ type: IS_LOADED });
      }
    });
};

export const getQuestion = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/sample/question/fetch", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_QUESTION, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const getBrandInfo = () => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .get("/drypers-baby-club/api/user/brands/fetch", tokenConfig(getState))
    .then(res => {
      dispatch({ type: GET_BRAND_INFO, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const editProfile = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/details/edit", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: EDIT_PROFILE, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const forceEditProfile = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/details/force/edit", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: FORCE_EDIT, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      // callback();
    })
    .catch(err => {
      console.log({ err });
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const displaySSO = data => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/ssoPopup", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: SSO_POPUP, payload: res.data });
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      // callback();
    })
    .catch(err => {
      console.log({ err });
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const trackOrder = (data, callback) => (dispatch, getState) => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/rewards/track", data, tokenConfig(getState))
    .then(res => {
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: IS_LOADED });
      callback(res.data);
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const forgotPassword = (data, callback) => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/password/forgot", data)
    .then(() => {
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const resetPassword = (data, callback) => dispatch => {
  dispatch({ type: IS_LOADING });
  axios
    .post("/drypers-baby-club/api/user/password/reset", data)
    .then(() => {
      dispatch({ type: IS_LOADED });
      callback();
    })
    .catch(err => {
      dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
      dispatch({ type: IS_LOADED });
    });
};

export const selectLanguage = language => dispatch => {
  axios.post("/drypers-baby-club/api/user/language", { language, token: localStorage.getItem("dbc-token") });
  dispatch({
    type: SELECT_LANG,
    payload: language
  });
};

export const addToCart = data => {
  return {
    type: ADD_TO_CART,
    payload: data
  };
};

export const editCart = data => {
  return {
    type: EDIT_CART,
    payload: data
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR
  };
};

export const logout = () => {
  return {
    type: LOG_OUT
  };
};

export const alreadyLoggedIn = () => {
  return {
    type: ALREADY_LOGIN
  };
};

export const saveCampaignId = data => {
  return {
    type: ADD_CAMPAIGN_ID,
    payload: data
  };
};

export const getMysteryBox = () => async (dispatch, getState) => {
  try {
    const res = await axios.get("/drypers-baby-club/api/campaign", tokenConfig(getState));
    dispatch(saveCampaignId(res.data?.campaign?.id));
    return res;
  } catch (error) {
    throw error;
  }
};

export const getMysteryBoxHistory = () => async (dispatch, getState) => {
  try {
    const res = await axios.get("/drypers-baby-club/api/campaign/entries", tokenConfig(getState));
    return res;
  } catch (error) {
    throw error;
  }
};

export const redeemMysteryBox = data => async (dispatch, getState) => {
  try {
    const res = await axios.post("/drypers-baby-club/api/campaign/redeem", data, tokenConfig(getState));
    return res;
  } catch (error) {
    throw error;
  }
};
